<script>
export default {
	name: "LogoSvg"
}
</script>

<template>
	<svg width="124" height="28" viewBox="0 0 124 28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<g id="Logo">
			<path id="Vector" d="M111.557 0C119.317 0 123.197 4.59635 123.197 13.7891C123.197 22.9818 119.245 27.5781 111.342 27.5781C103.438 27.5781 99.4863 22.8776 99.4863 13.4766C99.4863 4.49219 103.51 0 111.557 0ZM111.342 2.12891C109.883 2.12891 108.894 2.9362 108.373 4.55078C107.865 6.15234 107.611 9.23177 107.611 13.7891C107.611 18.3464 107.865 21.4323 108.373 23.0469C108.894 24.6484 109.883 25.4492 111.342 25.4492C112.8 25.4492 113.783 24.6484 114.291 23.0469C114.812 21.4323 115.072 18.3464 115.072 13.7891C115.072 9.24479 114.812 6.16536 114.291 4.55078C113.783 2.9362 112.8 2.12891 111.342 2.12891Z" fill="#F88AA1"/>
			<path id="Vector_2" d="M96.9951 0.46875V3.14453H87.4443V11.2109H95.4717V13.8867H87.4443V24.4531H98.6162V27.1289H79.71V0.46875H96.9951Z" fill="#F88AA1"/>
			<path id="Vector_3" d="M75.5205 0.46875V27.1289H68.0596V8.57422L62.8643 27.1289H58.5479L53.2158 8.88672V27.1289H50.6182V0.46875H58.4697L63.04 16.5625L67.5908 0.46875H75.5205Z" fill="#F88AA1"/>
			<path id="Vector_4" d="M36.0762 0C43.8366 0 47.7168 4.59635 47.7168 13.7891C47.7168 22.9818 43.765 27.5781 35.8613 27.5781C27.9577 27.5781 24.0059 22.8776 24.0059 13.4766C24.0059 4.49219 28.0293 0 36.0762 0ZM35.8613 2.12891C34.403 2.12891 33.4134 2.9362 32.8926 4.55078C32.3848 6.15234 32.1309 9.23177 32.1309 13.7891C32.1309 18.3464 32.3848 21.4323 32.8926 23.0469C33.4134 24.6484 34.403 25.4492 35.8613 25.4492C37.3197 25.4492 38.3027 24.6484 38.8105 23.0469C39.3314 21.4323 39.5918 18.3464 39.5918 13.7891C39.5918 9.24479 39.3314 6.16536 38.8105 4.55078C38.3027 2.9362 37.3197 2.12891 35.8613 2.12891Z" fill="#F88AA1"/>
			<path id="Vector_5" d="M0.304688 27.1289V0.46875H11.7695C14.7513 0.46875 17.1341 1.10026 18.918 2.36328C20.7018 3.61328 21.5938 5.29297 21.5938 7.40234C21.5938 10.6315 19.6797 12.7344 15.8516 13.7109C17.8177 14.4401 19.0872 15.2409 19.6602 16.1133C20.2461 16.9857 20.6432 18.6784 20.8516 21.1914C21.099 24.1862 21.6003 26.1654 22.3555 27.1289H13.957C13.4232 25.8919 13.1562 24.349 13.1562 22.5L13.1172 20.918L13.0781 18.9258C13.0781 15.8529 11.8411 14.3164 9.36719 14.3164H8.03906V27.1289H0.304688ZM8.03906 12.1094H9.60156C12.5312 12.1094 13.9961 10.5794 13.9961 7.51953C13.9961 4.45964 12.5312 2.92969 9.60156 2.92969H8.03906V12.1094Z" fill="#F88AA1"/>
		</g>
	</svg>
</template>