<script>
import {authMethods, authComputed, notificationMethods} from "@/state/helpers";
import LogoSvg from "@/components/Landing/LogoSvg.vue";
import Loader from "../../../components/Partials/Loader.vue";

export default {
	name: "magic-ink-login",
	components: {
		Loader,
		LogoSvg
	},
	computed: {
		...authComputed,
		notification() {
			return this.$store ? this.$store.state.notification : null;
		},
		notificationAutoCloseDuration() {
			return this.$store && this.$store.state.notification ? 5 : 0;
		},
	},
	methods: {
		...authMethods,
		...notificationMethods,
	},
	mounted() {
		this.loginWithMagicLink(this.$route.fullPath)
	}
}
</script>

<template>
	<div class="account-pages my-5 pt-sm-5">
		<div class="container">
			<div class="row justify-content-center">
				<div class="col-md-8 col-lg-6 col-xl-5">
					<div class="text-center mb-4">
						<router-link to="/" class="auth-logo mb-5 d-block">
							<LogoSvg />
						</router-link>
						<h4>Magic Link</h4>
					</div>

					<div class="card">
						<div class="card-body p-4">
							<div class="p-3">
								<b-alert
									:variant="notification.type"
									class="mt-3"
									v-if="notification.message"
									:show="notificationAutoCloseDuration"
									dismissible
								>{{ notification.message }}
								</b-alert>
								<Loader v-if="authLoading" />
							</div>
						</div>
					</div>

					<div class="mt-5 text-center">
						<p>
							<router-link
								tag="a"
								to="/login"
								class="font-weight-medium text-primary"
							>{{ $t('resetpassword.signinlink') }}
							</router-link>
						</p>
						<p>
							© {{ new Date().getFullYear() }}
							<i
								class="mdi mdi-heart text-danger"
							></i>
							Romeo
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">

</style>